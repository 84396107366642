import Alpine from 'alpinejs'
import Swiper, {Navigation, Pagination} from 'swiper';
import {register} from 'swiper/element/bundle';
import Glide from '@glidejs/glide'


import header from "./header";
import locationFinder from "./location-finder";
import basicSlider from "./basic-slider";
import glideSlider from "./glide-slider";
import usedVehicleSlider from "./used-vehicle-slider";
import threeSixty from "./360";
import carColorPicker from "./car-colorpicker";
import carMultiColorPicker from "./car-multicolorpicker";

import '../sass/main.scss';
import './ioniq6/ioniq6-page';
import './santafe/santafe-page';
import './bayon/bayon-page';

register();
locationFinder();

window.Glide = Glide;
window.Swiper = Swiper;
window.Alpine = Alpine

Alpine.data('header', header);
Alpine.data('basicSlider', basicSlider);
Alpine.data('usedVehicleSlider', usedVehicleSlider);
Alpine.data('glideSlider', glideSlider);
Alpine.data('threeSixty', threeSixty);
Alpine.data('carColorPicker', carColorPicker);
Alpine.data('carMultiColorPicker', carMultiColorPicker);

Alpine.start()


const inputs = document.querySelectorAll('.wpforms-field-container input, .wpforms-field-container textarea');


for (let i = 0; i < inputs.length; i++) {
inputs[i].placeholder = 'ㅤ'
}
